
export default {
  name: 'DateRange',
  props: {
    globalControl: {
      require: true,
      type: Object,
    },
    customObject: {
      require: false,
      type: Object,
    },
    prependIcon: {
      type: String,
      required: false,
      default: 'fas fa-calendar',
    },
    allowedDates: {
      type: Function,
      required: false,
    },
    maxDate: {
      type: String,
      required: false,
    },
  },
  data () {
    return {
      dates: [],
      startRangeDate: '',
      closeOnSelection: false,
    }
  },
  computed: {
    calendarColor () {
      const body = document.body
      const bgColor = getComputedStyle(body).getPropertyValue('--primary-color').trim()
      return bgColor
    },
    dateRangeText () {
      if (this.validateEmptyDates()) {
        return ''
      } else {
        return this.dates.join(' ~ ')
      }
    },
    locale () {
      return this.$i18n.locale
    },
  },
  methods: {
    resetDates () {
      this.$emit('onResetDates')
    },
    selectDates () {
      this.dates.sort()
      this.$emit('onSelectDates', this.dates)
      if (this.customObject) {
        this.customObject.value = this.dates[0]
        this.customObject.otherValue = this.dates[1]
      }
    },
    dateSelected () {
      this.closeOnSelection = this.dates.length > 1
      console.log(this.dates)
      if (this.dates.length === 1) {
        this.$emit('onInitSelection', this.dates[0])
      }
    },
    validateEmptyDates () {
      if (this.dates[0] === '' || this.dates[1] === '') {
        return true
      } else {
        return false
      }
    },
  },
  created () {
    this.dates = [
      this.globalControl.initDate,
      this.globalControl.endDate,
    ]
  },
}
